import { Honeybadger } from "@honeybadger-io/react";
import { authResponse } from "Utils/authentication";

/**
 * Determines whether notifications should be sent to Honeybadger based on the current environment.
 * Environments:
 * - production   : Should notify
 * - staging      : Should notify
 * - scraping     : Don't notify
 * - test         : Don't notify
 * - development  : Don't notify
 * */
export const shouldNotifyHoneybadger = ["production", "staging"].includes(process.env.ENV_NAME);

export default function installHoneybadger() {
  const honeybadger = Honeybadger.configure({
    apiKey: "ce8e8a3b",
    // Use env name with node_env as fallback
    environment: process.env.ENV_NAME || process.env.NODE_ENV,
    revision: "main",
    projectRoot: "webpack:///./",
  });

  honeybadger.beforeNotify((notice) => {
    // If someone downloads the HTML file and opens it locally, EVERYTHING is
    // broken, but we don't want to see these errors in our logs.
    if (!/^http/.test(location.protocol)) return false;
    return shouldNotifyHoneybadger;
  });

  authResponse.promise.then((response) => {
    if (response.account) {
      Honeybadger.setContext({
        account: response.account.id,
      });
    }
  });
  return honeybadger;
}
